* {
  padding: 0px;
  margin: 0px;
  border: none;
  }
  *,
  *::before,
  *::after {
  box-sizing: border-box;
  }
  body {
  background-color: hsla(0, 0%, 99%, 0.684);
  }
  nav {
  max-width: 1440px;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;   
  margin: 0 auto;
  border-bottom: 1px solid #3b3c3ec6;
  }
  .link {
  color:#3b3c3ec6;
  text-decoration: none;
  padding: 10px;
  text-transform: uppercase;
  font-family: 'Playfair Display', serif;
  font-weight: bolder;
  font-size:  calc(10px + 5 * (100vw / 1280));
  margin: 20px;    
  }
  .one {
  color: #99876E;
  }
  .content {
  width: 100%;
  }
  .head {
  display: flex;
  flex-direction: column;
  }
  .header {
  align-self: center;
  font-family: 'Cormorant Garamond', serif;
  font-weight:500;
  color: #434446;
  font-size:  calc(15px + 10 * (100vw / 1280));
  letter-spacing: 5px;
  padding: 10px;
  margin: 10px;
  opacity: 0;
  }
  .head_par {
  position: absolute;
  z-index: 3;
  top:10%;
  left: 20px;
  width: 40%;  
  font-size:  calc(10px + 20 * (100vw / 1280));   
  }
  .par {
  font-family: 'Cormorant Garamond', serif;
  color: #3B3C3E;   
  text-align: center;
  opacity: 0;   
  }
  .par_one {
  font-family: 'Cormorant Garamond', serif;
  color: #99876E;   
  font-weight: bold;    
  text-align: center;
  line-height: 2;
  opacity: 0;
  }
  .photo {
  align-self: center;
  position: relative;    
  }
  .photografer {
  width: 100%;
  height: auto;
  }
  .footer {
    margin-top: 30px;
  }
  .icon {
  width: 18px;
  vertical-align: bottom;
  }
  .icon-par {
  font-family: 'Playfair Display', serif;
  color: #434446df;
  font-size:  calc(10px + 5 * (100vw / 1280));
  margin-left: 10px;
  padding: 10px;
  }
  .btn {    
  position: absolute;
  top:50%; 
  background: none;
  border: none;     
  }
  .right {
  width: 100%;   
  }
  .left {
  width: 100%; 
  }
  .btn-left {
  left: 20px;
  }
  .btn-right {
  right: 20px;
  }
  /*My works*/
  .container {
  max-width: 1970px;
  background-color: #ead4d737; 
  }  
  .container_image {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  margin: 20px;
  align-self: center; 
  }
  .photos {
  display: flex;
  flex-direction: column;  
  align-items: center;
  padding: 10px;
  text-decoration: none;
  opacity:0;
  }
  .myphoto {
  width: 600px;
  height: 400px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.7); 
  }
  .photos:hover{
  transform: scale(1.03);
  transition: all 0.5s ease-in;  
  }
  .linkPhoto {
  color: #363925bb;
  text-transform: uppercase;
  font-size:  calc(10px + 8 * (100vw / 1280));
  font-weight:600;
  letter-spacing: 8px;
  font-family: 'Cormorant Garamond', serif;
  padding: 10px;
  }
  /*About*/
  .container_about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  padding: 10px;
  }
  .image_container {
  width: 50%;
  opacity: 0;
  }
  .image_about {
  width: 80%;   
  margin: 10px 0px 10px 40px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.7); 
  }
  .allpars {   
  align-items: center;
  width: 50%;
  margin-right: 40px;
  }
  .par_about {
  padding: 10px;
  font-family: 'Cormorant Garamond', serif;
  font-size:  calc(10px + 15 * (100vw / 1280));
  color: #363925;
  text-align: justify;
  opacity: 0;
  }
  .about__title {
  font-weight: 600;
  text-align: center;
  color: #99876E;
  }
  .image_par {
  text-align: center;
  padding: 10px;
  font-family: 'Playfair Display', serif;
  font-size:  calc(10px + 15 * (100vw / 1280));
  color: #363925;
  }
  /*Contact*/
  .container_contact {
  max-width: 1970px;   
  background-color: #ead4d737;
  }
  .cont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0px;  
  }
  .cont::after {          /*псевдоэлемент*/
  content: "";
  position: absolute;
  display: block;
  z-index: 2;
  width: 100%;
  height: 100%;  
  background-image: url("./img/contact-bg.png");  
  background-position: center;
  background-repeat: no-repeat;
  background-size:auto;
  }
  .contact_head {  
  padding: 10px;  
  margin: 10px;
  width: 70%;
  align-self: center;
  text-align: center;
  color: #363925bd;
  font-family: 'Playfair Display', serif;
  font-size:  calc(10px + 10 * (100vw / 1280));   
  letter-spacing: 3px;
  line-height: 1.8;
  z-index: 9;
  }
  .contact_head p {
  margin-bottom: 20px;
  margin-top: 20px;
  }
  .tel {
  font-weight: bold;
  color: #363925bd;
  text-decoration: none;
  } 
  .contact_par {
  align-self: center;
  width: 50%;
  background-color: #f5f2f1;
  padding: 30px 40px;  
  opacity: 0.6;
  border: 1px solid #363925bd;
  z-index: 5;
  }
  .cont_par {
  text-align: center;
  color: #363925;
  font-family: 'Cormorant Garamond', serif;
  font-size:  calc(10px + 15 * (100vw / 1280));
  margin-bottom: 30px;    
  }
  .input_par {
  color: #99876E;
  font-family: 'Playfair Display', serif;
  font-size:  calc(10px + 5 * (100vw / 1280));
  letter-spacing: 3px;
  margin-left: 10px;
  font-weight: bold;
  }
  .text_input:not([type=submit]) {
  margin: 10px;
  padding: 10px;
  font-family: 'Playfair Display', serif;
  font-size:  calc(10px + 5 * (100vw / 1280));
  color: #363925;
  background-color: #f5f2f1;
  width: 90%;
  outline: none;
  border-bottom: 1px solid #363925;
  border-left: none;
  border-top: none;
  border-right: none;
  }
  .input_btn {
  align-self: center;
  text-align: center;
  margin-top: 30px;
  color: #363925;
  font-family: 'Playfair Display', serif;
  font-size:  calc(10px + 5 * (100vw / 1280));
  letter-spacing: 5px;
  padding: 10px;
  background-color:#f5f2f1;
  border: 1px solid #363925;
  width: 50%;
  cursor: pointer;
  }
  .input_btn:hover{
  background-color:#ead4d7b6;
  transition: all 0.3s ease-in;
  }
  form {
  display: flex;
  flex-direction: column;
  opacity: 0;
  }
  .alert {
  display: flex;
  flex-direction: column;
  background-color: #f5f2f1;
  align-items: center;
  }
  .alert_par {
  color: #363925;
  font-family: 'Cormorant Garamond', serif;
  font-size:  calc(10px + 15 * (100vw / 1280));
  text-align: center;
  }
  .social {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 10px;  
  }
  .social__icons {
  width: 80px;
  cursor: pointer;
  }
  .social__icons:hover{
  transform: scale(1.2);
  transition: all 0.3s ease-in;    
  }
  /*Fammily*/
  .cards-family-grid {
  max-width: 1970px;
  display: grid;
  gap: 5px;
  margin: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(6, 320px);
  grid-template-areas:
  "wide1 wide2 wide4 wide4"
  "wide3 wide2 wide4 wide4"
  "wide5 wide5 wide6 wide7"
  "wide5 wide5 wide8 wide9"
  "wide10 wide11 wide12 wide12"
  "wide10 wide13 wide12 wide12"
  ;
  }
  .f-1 {grid-area: wide1;}
  .f-2 {grid-area: wide2;}
  .f-3 {grid-area: wide4;}
  .f-4 {grid-area: wide3;}
  .f-5 {grid-area: wide5;}
  .f-6 {grid-area: wide6;}
  .f-7 {grid-area: wide7;}
  .f-8 {grid-area: wide8;}
  .f-9 {grid-area: wide9;}
  .f-10 {grid-area: wide10;}
  .f-11 {grid-area: wide11;}
  .f-12 {grid-area: wide12;}
  .f-13 {grid-area: wide13;}
  /*все размеры фото*/
  .card {
    position: relative;
   
  }
  .card img {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 5px;   
  }
  /*Kids*/
  .cards-kids-grid {
  max-width: 1970px;
  display: grid;
  grid-gap: 5px;
  margin: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(8, 320px);
  grid-template-areas:
  "kids1 kids1 kids2 kids3"
  "kids1 kids1 kids4 kids5"
  "kids6 kids7 kids8 kids9"
  "kids10 kids7 kids8 kids11"
  "kids12 kids13 kids14 kids14"
  "kids12 kids15 kids14 kids14"
  "kids16 kids16 kids17 kids18"
  "kids16 kids16 kids17 kids19"
  ;
  }
  .k-1 {grid-area: kids1;}
  .k-2 {grid-area: kids2;}
  .k-3 {grid-area: kids3;}
  .k-4 {grid-area: kids4;}
  .k-5 {grid-area: kids5;}
  .k-6 {grid-area: kids7;}
  .k-7 {grid-area: kids8;}
  .k-8 {grid-area: kids6;}
  .k-9 {grid-area: kids10;}
  .k-10 {grid-area: kids9;}
  .k-11 {grid-area: kids11;}
  .k-12 {grid-area: kids12;}
  .k-13 {grid-area: kids13;}
  .k-14 {grid-area: kids15;}
  .k-15 {grid-area: kids14;}
  .k-16 {grid-area: kids16;}
  .k-17 {grid-area: kids17;}
  .k-18 {grid-area: kids18;}
  .k-19 {grid-area: kids19;}
  /*LoveStory*/
  .cards-love-grid {
    max-width: 1970px;
    display: grid;
    gap: 5px;
    margin: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(22, 320px);
    grid-template-areas:
    "l1 l1 l2 l3"
    "l1 l1 l2 l3"
    "l4 l5 l6 l6"
    "l4 l5 l6 l6"
    "l7 l7 l8 l8"
    "l7 l7 l8 l8"
    "l9 l9 l10 l10"
    "l9 l9 l10 l10"
    "l11 l11 l12 l13"
    "l11 l11 l12 l13"
    "l14 l15 l16 l16"
    "l14 l15 l16 l16"
    "l17 l17 l18 l19"
    "l17 l17 l18 l19"
    "l20 l21 l22 l22"
    "l20 l21 l22 l22"
    "l23 l24 l25 l26"
    "l23 l24 l25 l26"
    "l27 l27 l28 l30"
    "l27 l27 l29 l30"
    "l31 l33 l34 l35"
    "l32 l33 l34 l36"
    ;
  }
  .l-1 {grid-area: l1;}
  .l-2 {grid-area: l2;}
  .l-3 {grid-area: l3;}
  .l-4 {grid-area: l4;}
  .l-5 {grid-area: l5;}
  .l-6 {grid-area: l6;}
  .l-7 {grid-area: l7;}
  .l-8 {grid-area: l8;}
  .l-9 {grid-area: l9;}
  .l-10 {grid-area: l10;}
  .l-11 {grid-area: l11;}
  .l-12 {grid-area: l12;}
  .l-13 {grid-area: l13;}
  .l-14 {grid-area: l14;}
  .l-15 {grid-area: l15;}
  .l-16 {grid-area: l16;}
  .l-17 {grid-area: l17;}
  .l-18 {grid-area: l18;}
  .l-19 {grid-area: l19;}
  .l-20 {grid-area: l20;}
  .l-21 {grid-area: l21;}
  .l-22 {grid-area: l22;}
  .l-23 {grid-area: l23;}
  .l-24 {grid-area: l24;}
  .l-25 {grid-area: l25;}
  .l-26 {grid-area: l26;}
  .l-27 {grid-area: l27;}
  .l-28 {grid-area: l28;}
  .l-29 {grid-area: l29;}
  .l-30 {grid-area: l30;}
  .l-31 {grid-area: l31;}
  .l-32 {grid-area: l32;}
  .l-33 {grid-area: l33;}
  .l-34 {grid-area: l34;}
  .l-35 {grid-area: l35;}
  .l-36 {grid-area: l36;}

  /*New Year*/
  .cards-ny-grid {
    max-width: 1970px;
    display: grid;
    gap: 5px;
    margin: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(26, 360px);
    grid-template-areas:  
    "ny1 ny2 ny5 ny5"
    "ny3 ny4 ny5 ny5"  
    "ny6 ny7 ny8 ny9" 
    "ny6 ny7 ny8 ny10" 
    "ny11 ny11 ny12 ny13" 
    "ny11 ny11 ny12 ny14" 
    "ny15 ny16 ny17 ny17" 
    "ny15 ny16 ny17 ny17" 
    "ny18 ny18 ny19 ny20" 
    "ny18 ny18 ny19 ny21"
    "ny22 ny22 ny23 ny23" 
    "ny22 ny22 ny23 ny23"
    "ny24 ny26 ny27 ny28" 
    "ny25 ny26 ny27 ny28"
    "ny29 ny29 ny30 ny31" 
    "ny29 ny29 ny30 ny31"
    "ny32 ny33 ny35 ny35" 
    "ny32 ny34 ny35 ny35"
    "ny36 ny36 ny37 ny38" 
    "ny36 ny36 ny37 ny38"
    "ny39 ny41 ny43 ny43" 
    "ny40 ny42 ny43 ny43"
    "ny44 ny44 ny45 ny46" 
    "ny44 ny44 ny45 ny47"
    "ny48 ny49 ny50 ny52" 
    "ny48 ny49 ny51 ny53"
    ;
  }
  .ny-1 {grid-area: ny1;}
  .ny-2 {grid-area: ny2;}
  .ny-3 {grid-area: ny3;}
  .ny-4 {grid-area: ny4;}
  .ny-5 {grid-area: ny5;}
  .ny-6 {grid-area: ny6;}
  .ny-7 {grid-area: ny7;}
  .ny-8 {grid-area: ny8;}
  .ny-9 {grid-area: ny9;}
  .ny-10 {grid-area: ny10;}
  .ny-11 {grid-area: ny11;}
  .ny-12 {grid-area: ny12;}
  .ny-13 {grid-area: ny13;}
  .ny-14 {grid-area: ny14;}
  .ny-15 {grid-area: ny15;}
  .ny-16 {grid-area: ny16;}
  .ny-17 {grid-area: ny17;}
  .ny-18 {grid-area: ny18;}
  .ny-19 {grid-area: ny19;}
  .ny-20 {grid-area: ny20;}
  .ny-21 {grid-area: ny21;}
  .ny-22 {grid-area: ny22;}
  .ny-23 {grid-area: ny23;}
  .ny-24 {grid-area: ny24;}
  .ny-25 {grid-area: ny25;}
  .ny-26 {grid-area: ny26;}
  .ny-27 {grid-area: ny27;}
  .ny-28 {grid-area: ny28;}
  .ny-29 {grid-area: ny29;}
  .ny-30 {grid-area: ny30;}
  .ny-31 {grid-area: ny31;}
  .ny-32 {grid-area: ny32;}
  .ny-33 {grid-area: ny33;}
  .ny-34 {grid-area: ny34;}
  .ny-35 {grid-area: ny35;}
  .ny-36 {grid-area: ny36;}
  .ny-37 {grid-area: ny37;}
  .ny-38 {grid-area: ny38;}
  .ny-39 {grid-area: ny39;}
  .ny-40 {grid-area: ny40;}
  .ny-41 {grid-area: ny41;}
  .ny-42 {grid-area: ny42;}
  .ny-43 {grid-area: ny43;}
  .ny-44 {grid-area: ny44;}
  .ny-45 {grid-area: ny45;}
  .ny-46 {grid-area: ny46;}
  .ny-47 {grid-area: ny47;}
  .ny-48 {grid-area: ny48;}
  .ny-49 {grid-area: ny49;}
  .ny-50 {grid-area: ny50;}
  .ny-51 {grid-area: ny51;}
  .ny-52 {grid-area: ny52;}
  .ny-53 {grid-area: ny53;}

  /*Christ*/
  .cards-christ-grid {
  display: grid;
  gap: 5px;
  margin: 20px;
  justify-content: center;  
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(6, 420px);
  grid-template-areas:
  "christ1 christ2 christ3 christ3 christ4 christ4"
  "christ5 christ5 christ3 christ3 christ6 christ7"
  "christ8 christ9 christ10 christ10 christ11 christ11"
  "christ12 christ12 christ13 christ14 christ11 christ11"
  "christ15 christ15 christ16 christ16 christ17 christ17"
  "christ15 christ15 christ18 christ18 christ19 christ19" 
  ;
  }
  .c-1 {grid-area: christ1;}
  .c-2 {grid-area: christ2;}
  .c-3 {grid-area: christ3;}
  .c-4 {grid-area: christ4;}
  .c-5 {grid-area: christ5;}
  .c-6 {grid-area: christ6;}
  .c-7 {grid-area: christ7;}
  .c-8 {grid-area: christ8;}
  .c-9 {grid-area: christ9;}
  .c-10 {grid-area: christ10;}
  .c-11 {grid-area: christ11;}
  .c-12 {grid-area: christ12;}
  .c-13 {grid-area: christ13;}
  .c-14 {grid-area: christ14;}
  .c-15 {grid-area: christ15;}
  .c-16 {grid-area: christ16;}
  .c-17 {grid-area: christ17;}
  .c-18 {grid-area: christ18;}
  .c-19 {grid-area: christ19;}
  /*Pregnancy*/
  .cards-preg-grid {
  display: grid;
  gap: 5px;
  margin: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(7, 420px);
  grid-template-areas:
  "preg1 preg2 preg3 preg3 preg4 preg5"
  "preg6 preg7 preg3 preg3 preg8 preg8"
  "preg9 preg9 preg10 preg11 preg11 preg12"
  "preg13 preg14 preg15 preg11 preg11 preg16"
  "preg17 preg17 preg18 preg18 preg19 preg19"
  "preg17 preg17 preg20 preg20 preg19 preg19"
  "preg21 preg21 preg22 preg22 preg23 preg23"
  ;
  }
  .p-1 {grid-area: preg1;}
  .p-2 {grid-area: preg2;}
  .p-3 {grid-area: preg3;}
  .p-4 {grid-area: preg4;}
  .p-5 {grid-area: preg5;}
  .p-6 {grid-area: preg6;}
  .p-7 {grid-area: preg7;}
  .p-8 {grid-area: preg8;}
  .p-9 {grid-area: preg9;}
  .p-10 {grid-area: preg10;}
  .p-11 {grid-area: preg11;}
  .p-12 {grid-area: preg12;}
  .p-13 {grid-area: preg13;}
  .p-14 {grid-area: preg14;}
  .p-15 {grid-area: preg15;}
  .p-16 {grid-area: preg16;}
  .p-17 {grid-area: preg17;}
  .p-18 {grid-area: preg18;}
  .p-19 {grid-area: preg19;}
  .p-20 {grid-area: preg20;}
  .p-21 {grid-area: preg21;}
  .p-22 {grid-area: preg22;}
  .p-23 {grid-area: preg23;}
  /*Wedding*/
  .cards-wedding-grid {
    display: grid;
    gap: 5px;
    margin: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(12, 420px);
    grid-template-areas:
    "w1 w1 w2 w2 w3 w4"
    "w5 w6 w2 w2 w7 w7"
    "w8 w9 w10 w11 w11 w12"
    "w13 w13 w14 w11 w11 w15"
    "w16 w16 w17 w17 w18 w18"
    "w16 w16 w19 w20 w21 w22"
    "w23 w23 w24 w24 w25 w25"
    "w23 w23 w24 w24 w25 w25"
    "w26 w26 w27 w27 w28 w28"
    "w29 w30 w27 w27 w31 w32"
    "w33 w33 w38 w39 w35 w35"
    "w36 w37 w34 w34 w40 w41"
  }
  .w-1 {grid-area: w1;}
  .w-2 {grid-area: w2;}
  .w-3 {grid-area: w3;}
  .w-4 {grid-area: w4;}
  .w-5 {grid-area: w5;}
  .w-6 {grid-area: w6;}
  .w-7 {grid-area: w7;}
  .w-8 {grid-area: w8;}
  .w-9 {grid-area: w9;}
  .w-10 {grid-area: w10;}
  .w-11 {grid-area: w11;}
  .w-12 {grid-area: w12;}
  .w-13 {grid-area: w13;}
  .w-14 {grid-area: w14;}
  .w-15 {grid-area: w15;}
  .w-16 {grid-area: w16;}
  .w-17 {grid-area: w17;}
  .w-18 {grid-area: w18;}
  .w-19 {grid-area: w19;}
  .w-20 {grid-area: w20;}
  .w-21 {grid-area: w21;}
  .w-22 {grid-area: w22;}
  .w-23 {grid-area: w23;}
  .w-24 {grid-area: w24;}
  .w-25 {grid-area: w25;}
  .w-26 {grid-area: w26;}
  .w-27 {grid-area: w27;}
  .w-28 {grid-area: w28;}
  .w-29 {grid-area: w29;}
  .w-30 {grid-area: w30;}
  .w-31 {grid-area: w31;}
  .w-32 {grid-area: w32;}
  .w-33 {grid-area: w33;}
  .w-34 {grid-area: w34;}
  .w-35 {grid-area: w35;}
  .w-36 {grid-area: w36;}
  .w-37 {grid-area: w37;}
  .w-38 {grid-area: w38;}
  .w-39 {grid-area: w39;}
  .w-40 {grid-area: w40;}
  .w-41 {grid-area: w41;}
/*Women*/
  .cards-women-grid {
    display: grid;
    gap: 5px;
    margin: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(14, 460px);
    grid-template-areas:
    "wm1 wm2 wm5 wm5 wm6 wm7"
    "wm3 wm4 wm5 wm5 wm8 wm8"
    "wm9 wm9 wm12 wm13 wm16 wm16"
    "wm10 wm11 wm14 wm15 wm16 wm16"
    "wm17 wm19 wm21 wm21 wm22 wm24"
    "wm18 wm20 wm21 wm21 wm23 wm25"
    "wm26 wm26 wm27 wm28 wm30 wm31"
    "wm26 wm26 wm29 wm29 wm32 wm33"
    "wm34 wm34 wm36 wm36 wm37 wm37"
    "wm35 wm35 wm36 wm36 wm38 wm38"
    "wm39 wm40 wm42 wm42 wm45 wm45"
    "wm41 wm41 wm43 wm44 wm45 wm45"
    "wm46 wm47 wm49 wm49 wm50 wm50"
    "wm48 wm48 wm49 wm49 wm51 wm52"
    ;
  }
  .wm-1 {grid-area: wm1;}
  .wm-2 {grid-area: wm2;}
  .wm-3 {grid-area: wm3;}
  .wm-4 {grid-area: wm4;}
  .wm-5 {grid-area: wm5;}
  .wm-6 {grid-area: wm6;}
  .wm-7 {grid-area: wm7;}
  .wm-8 {grid-area: wm8;}
  .wm-9 {grid-area: wm9;}
  .wm-10 {grid-area: wm10;}
  .wm-11 {grid-area: wm11;}
  .wm-12 {grid-area: wm12;}
  .wm-13 {grid-area: wm13;}
  .wm-14 {grid-area: wm14;}
  .wm-15 {grid-area: wm15;}
  .wm-16 {grid-area: wm16;}
  .wm-17 {grid-area: wm17;}
  .wm-18 {grid-area: wm18;}
  .wm-19 {grid-area: wm19;}
  .wm-20 {grid-area: wm20;}
  .wm-21 {grid-area: wm21;}
  .wm-22 {grid-area: wm22;}
  .wm-23 {grid-area: wm23;}
  .wm-24 {grid-area: wm24;}
  .wm-25 {grid-area: wm25;}
  .wm-26 {grid-area: wm26;}
  .wm-27 {grid-area: wm27;}
  .wm-28 {grid-area: wm28;}
  .wm-29 {grid-area: wm29;}
  .wm-30 {grid-area: wm30;}
  .wm-31 {grid-area: wm31;}
  .wm-32 {grid-area: wm32;}
  .wm-33 {grid-area: wm33;}
  .wm-34 {grid-area: wm34;}
  .wm-35 {grid-area: wm35;}
  .wm-36 {grid-area: wm36;}
  .wm-37 {grid-area: wm37;}
  .wm-38 {grid-area: wm38;}
  .wm-39 {grid-area: wm39;}
  .wm-40 {grid-area: wm40;}
  .wm-41 {grid-area: wm41;}
  .wm-42 {grid-area: wm42;}
  .wm-43 {grid-area: wm43;}
  .wm-44 {grid-area: wm44;}
  .wm-45 {grid-area: wm45;}
  .wm-46 {grid-area: wm46;}
  .wm-47 {grid-area: wm47;}
  .wm-48 {grid-area: wm48;}
  .wm-49 {grid-area: wm49;}
  .wm-50 {grid-area: wm50;}
  .wm-51 {grid-area: wm51;}
  .wm-52 {grid-area: wm52;}

  /*Birthday*/
  .cards-birth-grid {
    max-width: 1970px;
    display: grid;
    gap: 5px;
    margin: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(10, 360px);
    grid-template-areas:
    "b1 b2 b5 b6"
    "b3 b4 b5 b6"
    "b7 b9 b10 b10"
    "b8 b9 b10 b10"
    "b11 b12 b13 b14"
    "b11 b12 b13 b14"
    "b15 b15 b16 b18"
    "b15 b15 b17 b18"
    "b19 b20 b21 b21"
    "b19 b20 b21 b21"
  
  }
  .b-1 {grid-area: b1;}
  .b-2 {grid-area: b2;}
  .b-3 {grid-area: b3;}
  .b-4 {grid-area: b4;}
  .b-5 {grid-area: b5;}
  .b-6 {grid-area: b6;}
  .b-7 {grid-area: b7;}
  .b-8 {grid-area: b8;}
  .b-9 {grid-area: b9;}
  .b-10 {grid-area: b10;}
  .b-11 {grid-area: b11;}
  .b-12 {grid-area: b12;}
  .b-13 {grid-area: b13;}
  .b-14 {grid-area: b14;}
  .b-15 {grid-area: b15;}
  .b-16 {grid-area: b16;}
  .b-17 {grid-area: b17;}
  .b-18 {grid-area: b18;}
  .b-19 {grid-area: b19;}
  .b-20 {grid-area: b20;}
  .b-21 {grid-area: b21;}
/*Newborn*/
.cards-newborn-grid {
  max-width: 1970px;
  display: grid;
  gap: 5px;
  margin: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(12, 360px);
  grid-template-areas:
  "n1 n2 n4 n5"
  "n1 n3 n4 n6"
  "n7 n9 n10 n10"
  "n8 n9 n10 n10"
  "n11 n11 n12 n13"
  "n11 n11 n12 n14"
  "n15 n16 n18 n18"
  "n15 n17 n18 n18"
  "n19 n19 n20 n21"
  "n19 n19 n20 n21"
  "n22 n23 n24 n24"
  "n22 n23 n24 n24"
  ;
  }
  .n-1 {grid-area: n1;}
  .n-2 {grid-area: n2;}
  .n-3 {grid-area: n3;}
  .n-4 {grid-area: n4;}
  .n-5 {grid-area: n5;}
  .n-6 {grid-area: n6;}
  .n-7 {grid-area: n7;}
  .n-8 {grid-area: n8;}
  .n-9 {grid-area: n9;}
  .n-10 {grid-area: n10;}
  .n-11 {grid-area: n11;}
  .n-12 {grid-area: n12;}
  .n-13 {grid-area: n13;}
  .n-14 {grid-area: n14;}
  .n-15 {grid-area: n15;}
  .n-16 {grid-area: n16;}
  .n-17 {grid-area: n17;}
  .n-18 {grid-area: n18;}
  .n-19 {grid-area: n19;}
  .n-20 {grid-area: n20;}
  .n-21 {grid-area: n21;}
  .n-22 {grid-area: n22;}
  .n-23 {grid-area: n23;}
  .n-24 {grid-area: n24;}

/*Daughter*/
.cards-daughter-grid {
  max-width: 1970px;
  display: grid;
  gap: 5px;
  margin: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(12, 360px);
  grid-template-areas:
  "d1 d1 d2 d3"
  "d1 d1 d2 d3"
  "d4 d5 d7 d7"
  "d4 d6 d7 d7"
  "d8 d8 d9 d10"
  "d8 d8 d9 d10"
  "d11 d12 d13 d13"
  "d11 d12 d13 d13"
  "d14 d15 d16 d17"
  "d14 d15 d16 d17"
  "d18 d18 d19 d20"
  "d18 d18 d19 d20"
  ;
  }
  .d-1 {grid-area: d1;}
  .d-2 {grid-area: d2;}
  .d-3 {grid-area: d3;}
  .d-4 {grid-area: d4;}
  .d-5 {grid-area: d5;}
  .d-6 {grid-area: d6;}
  .d-7 {grid-area: d7;}
  .d-8 {grid-area: d8;}
  .d-9 {grid-area: d9;}
  .d-10 {grid-area: d10;}
  .d-11 {grid-area: d11;}
  .d-12 {grid-area: d12;}
  .d-13 {grid-area: d13;}
  .d-14 {grid-area: d14;}
  .d-15 {grid-area: d15;}
  .d-16 {grid-area: d16;}
  .d-17 {grid-area: d17;}
  .d-18 {grid-area: d18;}
  .d-19 {grid-area: d19;}
  .d-20 {grid-area: d20;}

/*Winter*/
.cards-winter-grid {
  max-width: 1970px;
  display: grid;
  gap: 5px;
  margin: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(10, 360px);
  grid-template-areas:
  "wt1 wt2 wt4 wt4"
  "wt1 wt3 wt4 wt4"
  "wt5 wt5 wt6 wt7"
  "wt5 wt5 wt6 wt7"
  "wt8 wt9 wt10 wt10"
  "wt8 wt9 wt10 wt10"
  "wt11 wt11 wt12 wt13"
  "wt11 wt11 wt14 wt15"
  "wt16 wt17 wt18 wt19"
  "wt16 wt17 wt18 wt19"
  ;
  }
  .wt-1 {grid-area: wt1;}
  .wt-2 {grid-area: wt2;}
  .wt-3 {grid-area: wt3;}
  .wt-4 {grid-area: wt4;}
  .wt-5 {grid-area: wt5;}
  .wt-6 {grid-area: wt6;}
  .wt-7 {grid-area: wt7;}
  .wt-8 {grid-area: wt8;}
  .wt-9 {grid-area: wt9;}
  .wt-10 {grid-area: wt10;}
  .wt-11 {grid-area: wt11;}
  .wt-12 {grid-area: wt12;}
  .wt-13 {grid-area: wt13;}
  .wt-14 {grid-area: wt14;}
  .wt-15 {grid-area: wt15;}
  .wt-16 {grid-area: wt16;}
  .wt-17 {grid-area: wt17;}
  .wt-18 {grid-area: wt18;}
  .wt-19 {grid-area: wt19;}

  /*LOADER*/
  .container_loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 99%, 0.684);
  }
  .lds-roller {
  position: absolute;
  top: 45%;
  left: 45%;
  }
  .lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  }
  .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
  z-index: 2;
  }
  .lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
  }
  .lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
  }
  .lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
  }
  .lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
  }
  .lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
  }
  .lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
  }
  .lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
  }
  .lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
  }
  @keyframes lds-roller {
  0% {
  transform: rotate(0deg);
  }
  100% {
  transform: rotate(360deg);
  }
  }

  @media all and (min-height:740px) and (max-width:650px) {
    .head_par {    
      position:static;
      margin-top: 20px;
      margin-bottom: 100px;
      padding: 10px 40px;
      align-items: center;
      width: 100%;  
      }

    .btn {
      top:30%; 
      }
    .photo {    
      position: relative;    
    }
  }
  @media all and (min-height:830px) and (max-width:650px) {
    .head_par {      
      margin-bottom: 200px;
    }
  }
  @media all and (min-height:1000px) and (max-width:650px) {
    .head_par {      
      margin-bottom: 300px;
    }
  }

  @media all and (max-width:1650px) {
    .cards-family-grid {
      grid-template-rows: repeat(6, 280px);
    }
    .cards-love-grid {
      grid-template-rows: repeat(22, 280px);
    }
    .cards-kids-grid {
      grid-template-rows: repeat(8, 280px);
    }
    .cards-daughter-grid {
      grid-template-rows: repeat(12, 280px);
    }
    .cards-newborn-grid {
      grid-template-rows: repeat(12, 280px);
    }
    .cards-ny-grid {
      grid-template-rows: repeat(26, 280px);
    }
    .cards-winter-grid {
      grid-template-rows: repeat(10, 280px);
    }
    .cards-birth-grid {
      grid-template-rows: repeat(10, 280px);
    }
    .cards-women-grid { 
      grid-template-rows: repeat(14, 380px);
    }
    .cards-wedding-grid {
      grid-template-rows: repeat(12, 380px);
    }
    .cards-christ-grid {
      grid-template-rows: repeat(6, 380px);
    }
    .cards-preg-grid {
      grid-template-rows: repeat(7, 420px);
    }

  }

  @media all and (max-width:1560px) {
    .cards-wedding-grid {   
      grid-template-rows: repeat(18, 480px);
      grid-template-areas:
      "w1 w1 w2 w2"
      "w5 w6 w2 w2"
      "w7 w7 w3 w4"
      "w8 w9 w10 w12"
      "w11 w11 w13 w13"
      "w11 w11 w14 w15"
      "w17 w17 w16 w16" 
      "w18 w18 w16 w16"
      "w24 w24 w23 w23"
      "w24 w24 w23 w23"
      "w25 w25 w21 w22"
      "w25 w25 w19 w20"
      "w28 w28 w31 w32"
      "w26 w26 w27 w27"
      "w29 w30 w27 w27"
      "w33 w33 w38 w39"
      "w35 w35 w36 w37"
      "w34 w34 w40 w41"
      ;
      }
      .cards-women-grid {  
        grid-template-rows: repeat(21, 480px);
        grid-template-areas:
        "wm1 wm2 wm5 wm5"
        "wm3 wm4 wm5 wm5"
        "wm6 wm7 wm9 wm9"
        "wm8 wm8 wm10 wm11"
        "wm16 wm16 wm12 wm13"
        "wm16 wm16 wm14 wm15"
        "wm17 wm19 wm18 wm20"
        "wm21 wm21 wm22 wm24"
        "wm21 wm21 wm23 wm25"
        "wm26 wm26 wm27 wm28"
        "wm26 wm26 wm29 wm29"
        "wm30 wm31 wm32 wm33"
        "wm34 wm34 wm36 wm36"
        "wm35 wm35 wm36 wm36"
        "wm37 wm37 wm38 wm38"
        "wm39 wm40 wm42 wm42"
        "wm41 wm41 wm43 wm44"
        "wm45 wm45 wm46 wm47"
        "wm45 wm45 wm48 wm48"
        "wm49 wm49 wm50 wm50"
        "wm49 wm49 wm51 wm52"
        ;
      }
      .cards-christ-grid {        
        grid-template-rows: repeat(9, 480px);
        grid-template-areas:
        "christ1 christ2 christ3 christ3" 
        "christ5 christ5 christ3 christ3"
        "christ4 christ4 christ6 christ7"
        "christ8 christ9 christ10 christ10"
        "christ11 christ11 christ12 christ12"
        "christ11 christ11 christ13 christ14 "
        "christ17 christ17 christ19 christ19"
        "christ15 christ15 christ16 christ16"
        "christ15 christ15 christ18 christ18"          
        ;
        }
        .cards-preg-grid {  
          grid-template-rows: repeat(10, 520px);
          grid-template-areas:
          "preg1 preg2 preg3 preg3"
          "preg6 preg7 preg3 preg3"
          "preg4 preg5 preg8 preg8"
          "preg11 preg11 preg9 preg9"
          "preg11 preg11 preg10 preg12"      
          "preg13 preg14 preg15 preg16"
          "preg17 preg17 preg18 preg18"
          "preg17 preg17 preg20 preg20"
          "preg21 preg21 preg19 preg19"
          "preg23 preg23 preg19 preg19"          
          ;
          }
  }
  @media all and (max-width:1480px) {
  nav {
  justify-content: center;
  column-gap: 20px;   
  }
  .input_btn {
  width: 80%;
  }
  .cards-family-grid {
    grid-template-rows: repeat(6, 240px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(8, 240px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(12, 240px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(12, 240px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(26, 240px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(10, 240px);
  }
 .cards-birth-grid { 
  grid-template-rows: repeat(10, 240px);
  }
  }
  @media all and (max-width:1350px) {
  .myphoto {
  width: 540px;  
  height: 360px;
  }
  .cards-birth-grid { 
  grid-template-rows: repeat(10, 220px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(22, 240px);
  }
 
  }
  @media all and (max-width:1220px) {
  .myphoto {
  width: 480px;  
  height: 320px;
  }
  .cards-family-grid {
  grid-template-rows: repeat(12, 420px);
  grid-template-areas:
  "wide1 wide2"
  "wide3 wide2" 
  "wide4 wide4"
  "wide4 wide4"
  "wide5 wide5"
  "wide5 wide5" 
  "wide6 wide7"
  "wide8 wide9"
  "wide10 wide11"
  "wide10 wide13"
  "wide12 wide12"
  "wide12 wide12"
  ;
  }
  .cards-kids-grid {  
  grid-template-rows: repeat(16, 420px);
  grid-template-areas:
  "kids1 kids1"
  "kids1 kids1"
  "kids2 kids3"
  "kids4 kids5"
  "kids6 kids7"
  "kids10 kids7"
  "kids8 kids9"
  "kids8 kids11"
  "kids12 kids13" 
  "kids12 kids15"
  "kids14 kids14"
  "kids14 kids14"
  "kids16 kids16"             
  "kids16 kids16"
  "kids17 kids18"
  "kids17 kids19"            
  ;
  }
  .cards-love-grid {   
    grid-template-rows: repeat(43, 400px);
    grid-template-areas:
    "l1 l1"
    "l1 l1"
    "l2 l3"
    "l2 l3"
    "l4 l5"
    "l4 l5"
    "l6 l6"
    "l6 l6"
    "l7 l7"
    "l7 l7"
    "l8 l8"
    "l8 l8"
    "l9 l9"
    "l9 l9"
    "l10 l10"
    "l10 l10"
    "l11 l11"
    "l11 l11"
    "l12 l13"
    "l12 l13"
    "l14 l15"
    "l14 l15"
    "l16 l16"
    "l16 l16"
    "l17 l17"
    "l17 l17"
    "l18 l19"
    "l20 l21"
    "l20 l21"
    "l22 l22"
    "l22 l22"
    "l23 l24"
    "l23 l24"
    "l25 l26"
    "l25 l26"
    "l27 l27"
    "l27 l27"
    "l28 l30"
    "l29 l30"
    "l31 l33"
    "l32 l33"
    "l34 l35"
    "l34 l36"
  ;
  }
  .cards-newborn-grid {   
    grid-template-rows: repeat(24, 400px);
    grid-template-areas:
    "n1 n2"
    "n1 n3"
    "n4 n5"
    "n4 n6"
    "n10 n10"
    "n10 n10"
    "n7 n9" 
    "n8 n9"
    "n11 n11" 
    "n11 n11"
    "n12 n13"
    "n12 n14"
    "n18 n18"
    "n18 n18"
    "n15 n16" 
    "n15 n17" 
    "n19 n19" 
    "n19 n19"
    "n20 n21"
    "n20 n21"
    "n24 n24" 
    "n24 n24"
    "n22 n23" 
    "n22 n23" 
  ;
  }
  .cards-daughter-grid {   
    grid-template-rows: repeat(24, 400px);
    grid-template-areas:
    "d1 d1" 
    "d1 d1"
    "d2 d3"
    "d2 d3" 
    "d4 d5" 
    "d4 d6"
    "d7 d7"
    "d7 d7"
    "d9 d10"
    "d9 d10" 
    "d8 d8" 
    "d8 d8" 
    "d11 d12" 
    "d11 d12"
    "d13 d13"
    "d13 d13" 
    "d14 d15"
    "d14 d15"
    "d18 d18"
    "d18 d18"
    "d16 d17"
    "d16 d17"
    "d19 d20"
    "d19 d20"
  ;
  }
  .cards-ny-grid {   
    grid-template-rows: repeat(52, 400px);
    grid-template-areas:
    "ny1 ny2" 
    "ny3 ny4"
    "ny5 ny5" 
    "ny5 ny5" 
    "ny6 ny7" 
    "ny6 ny7"
    "ny8 ny9"
    "ny8 ny10" 
    "ny11 ny11" 
    "ny11 ny11"
    "ny12 ny13"
    "ny12 ny14" 
    "ny15 ny16" 
    "ny15 ny16" 
    "ny17 ny17"
    "ny17 ny17" 
    "ny18 ny18" 
    "ny18 ny18"
    "ny19 ny20"
    "ny19 ny21"
    "ny22 ny22" 
    "ny22 ny22"
    "ny23 ny23"    
    "ny23 ny23"
    "ny24 ny26" 
    "ny25 ny26"
    "ny27 ny28"
    "ny27 ny28"
    "ny29 ny29" 
    "ny29 ny29" 
    "ny30 ny31"
    "ny30 ny31"
    "ny32 ny33" 
    "ny32 ny34" 
    "ny35 ny35"
    "ny35 ny35"
    "ny36 ny36" 
    "ny36 ny36"
    "ny37 ny38"
    "ny37 ny38"
    "ny39 ny41" 
    "ny40 ny42"
    "ny43 ny43"
    "ny43 ny43"
    "ny44 ny44" 
    "ny44 ny44"
    "ny45 ny46"
    "ny45 ny47"
    "ny48 ny49" 
    "ny48 ny49" 
    "ny50 ny52"
    "ny51 ny53"
    ;
  }
  .cards-birth-grid {   
    grid-template-rows: repeat(20, 400px);
    grid-template-areas:
    "b1 b2"
    "b3 b4" 
    "b5 b6"
    "b5 b6"
    "b7 b9"
    "b8 b9" 
    "b10 b10"
    "b10 b10"
    "b11 b12"
    "b11 b12"
    "b13 b14"
    "b13 b14"
    "b15 b15"
    "b15 b15" 
    "b16 b18"
    "b17 b18"
    "b19 b20"
    "b19 b20"
    "b21 b21" 
    "b21 b21"
    ;
  }
  .cards-winter-grid {    
    grid-template-rows: repeat(20, 400px);
    grid-template-areas:
    "wt1 wt2"
    "wt1 wt3" 
    "wt4 wt4"
    "wt4 wt4"
    "wt5 wt5"
    "wt5 wt5" 
    "wt6 wt7"
    "wt6 wt7"
    "wt8 wt9"
    "wt8 wt9" 
    "wt10 wt10"
    "wt10 wt10"
    "wt11 wt11"
    "wt11 wt11" 
    "wt12 wt13"
    "wt14 wt15"
    "wt16 wt17"
    "wt16 wt17"  
    "wt18 wt19"
    "wt18 wt19"
    ;
  }
  .cards-wedding-grid {
    grid-template-rows: repeat(18, 400px);
  }
  .cards-women-grid {  
    grid-template-rows: repeat(21, 400px);
  }
  .cards-christ-grid {  
    grid-template-rows: repeat(9, 400px); 
    }
    .cards-preg-grid {
      grid-template-rows: repeat(10, 400px); 
    }
  }
  @media all and (max-width:1100px) {  
  .link {
  padding: 15px;     
  }
  .myphoto {
  width: 420px;  
  height: 280px;
  }
  .container_image {
  column-gap: 10px;
  margin: 10px;
  }
  .contact_par { 
  width: 60%;   
  }
  .cards-wedding-grid {
    grid-template-rows: repeat(18, 380px);
  }
  .cards-women-grid {  
    grid-template-rows: repeat(21, 380px);
  }
  .cards-christ-grid {  
    grid-template-rows: repeat(9, 380px); 
  }
  .cards-preg-grid {
    grid-template-rows: repeat(10, 380px); 
  }
  .cards-family-grid {
    grid-template-rows: repeat(12, 360px);
}
  .cards-kids-grid {
    grid-template-rows: repeat(16, 360px);
}
.cards-daughter-grid {
  grid-template-rows: repeat(24, 360px);
}
.cards-newborn-grid {
  grid-template-rows: repeat(24, 360px);
}
.cards-ny-grid {
  grid-template-rows: repeat(52, 360px);
}
.cards-winter-grid {
  grid-template-rows: repeat(20, 360px);
}
.cards-birth-grid {
  grid-template-rows: repeat(20, 360px);
}
  }
  @media all and (max-width:1055px) {
  .cards-preg-grid { 
  grid-template-columns: repeat(4, 220px);
  grid-template-rows: repeat(10, 300px);
  }
  .cards-wedding-grid {
    grid-template-columns: repeat(4, 220px);
    grid-template-rows: repeat(18, 320px);
  }
  .cards-women-grid {  
    grid-template-columns: repeat(4, 220px);
    grid-template-rows: repeat(21, 320px);
  }
  .cards-christ-grid { 
    grid-template-columns: repeat(4, 220px); 
    grid-template-rows: repeat(9, 320px); 
    }
  }
  @media all and (max-width:950px) {  
  .myphoto {
  width: 360px;  
  height: 240px;
  }
  .contact_head p {
    margin-top: 0px;
  }
  .cards-family-grid {
    grid-template-rows: repeat(12, 320px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(16, 320px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(24, 320px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(24, 320px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(52, 320px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(20, 320px);
  }
 .cards-birth-grid {  
  grid-template-rows: repeat(20, 320px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(43, 340px);
  }
  .cards-wedding-grid {
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: repeat(18, 300px);
  }
  .cards-women-grid {  
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: repeat(21, 300px);
  }
  .cards-christ-grid { 
    grid-template-columns: repeat(4, 200px); 
    grid-template-rows: repeat(9, 300px); 
    }
    .cards-preg-grid { 
      grid-template-columns: repeat(4, 200px);
      grid-template-rows: repeat(10, 300px);
      }
  }
  
  @media all and (max-width:825px) {
  .link {
  padding: 5px;
  }
  .myphoto {
  width: 315px;  
  height: 210px;
  }
  .par_about {     
  padding: 2px;
  }
  .cards-women-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(42, 540px);
    grid-template-areas:
    "wm1 wm2"
    "wm3 wm4" 
    "wm5 wm5"
    "wm5 wm5"
    "wm6 wm7"
    "wm8 wm8"
    "wm9 wm9"
    "wm10 wm11"
    "wm16 wm16"
    "wm16 wm16" 
    "wm12 wm13"
    "wm14 wm15"
    "wm17 wm19"
    "wm18 wm20"
    "wm21 wm21"
    "wm21 wm21"
    "wm22 wm24"
    "wm23 wm25"
    "wm26 wm26"
    "wm26 wm26"
    "wm27 wm28"
    "wm29 wm29"
    "wm30 wm31"
    "wm32 wm33"
    "wm34 wm34"
    "wm35 wm35" 
    "wm36 wm36"
    "wm36 wm36"
    "wm37 wm37"
    "wm38 wm38"
    "wm39 wm40"
    "wm41 wm41"
    "wm42 wm42"
    "wm43 wm44"
    "wm45 wm45"
    "wm45 wm45"
    "wm46 wm47"
    "wm48 wm48"
    "wm49 wm49"
    "wm49 wm49"
    "wm50 wm50"
    "wm51 wm52"
    ;
  }
  .cards-love-grid {
    grid-template-rows: repeat(43, 285px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(52, 285px);
  }
  .cards-birth-grid {  
    grid-template-rows: repeat(20, 285px);
    }
  .cards-preg-grid { 
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(21,540px);
  grid-template-areas:
  "preg1 preg2"
  "preg3 preg3"
  "preg3 preg3"
  "preg4 preg5"
  "preg6 preg7"
  "preg8 preg8"
  "preg9 preg9"
  "preg10  preg12"
  "preg11 preg11"
  "preg11 preg11" 
  "preg13 preg14"
  "preg17 preg17"
  "preg17 preg17"
  "preg15  preg16"
  "preg18 preg18"
  "preg19 preg19"
  "preg19 preg19"
  "preg20 preg20"
  "preg21 preg21"
  "preg22 preg22"
  "preg23 preg23"
  ;
  }
  .cards-christ-grid {
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: repeat(18, 540px);
  grid-template-areas:
  "christ1 christ2" 
  "christ5 christ5"
  "christ3 christ3" 
  "christ3 christ3"
  "christ4 christ4"
  "christ6 christ7"
  "christ8 christ9"
  "christ10 christ10"
  "christ11 christ11"
  "christ11 christ11"
  "christ13 christ14"
  "christ12 christ12"
  "christ17 christ17"
  "christ19 christ19"
  "christ15 christ15"
  "christ15 christ15"
  "christ16 christ16"
  "christ18 christ18"          
  ;
  }
  .cards-wedding-grid { 
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(36, 540px);
    grid-template-areas:
    "w2 w2"
    "w2 w2"
    "w1 w1"
    "w5 w6"
    "w7 w7"
    "w3 w4"
    "w8 w9"
    "w10 w12"
    "w11 w11"
    "w11 w11"
    "w13 w13"
    "w14 w15"
    "w17 w17"
    "w16 w16" 
    "w16 w16"
    "w18 w18"
    "w24 w24"
    "w24 w24"
    "w23 w23"
    "w23 w23"
    "w25 w25"
    "w25 w25"
    "w21 w22"
    "w19 w20"
    "w28 w28"
    "w31 w32"
    "w26 w26"
    "w27 w27"
    "w27 w27"
    "w29 w30"
    "w33 w33"
    "w35 w35"
    "w38 w39"
    "w36 w37"
    "w34 w34"
    "w40 w41"
    ;
    }
 

  }
  @media all and (max-width:800px) {  
  .header {
  letter-spacing: 2px;   
  }
  .right {
  width: 80%;   
  }
  .left {
  width: 80%; 
  }
  .btn-left {
  left: 5px;
  }
  .btn-right {
  right: 5px;
  }   
  .container_about {   
  column-gap: 10px;
  padding: 5px;
  }
  .image_container {
  width: 50%;
  }
  .image_about {  
  margin: 10px 0px 10px 20px; 
  }
  .allpars {      
  margin-right: 20px;
  } 
  .cards-family-grid {
    grid-template-rows: repeat(12, 280px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(16, 280px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(24, 280px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(24, 280px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(52, 280px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(20, 280px);
  }
  .cards-birth-grid {
  grid-template-rows: repeat(20, 280px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(43, 260px);
  }
 
  }
  @media all and (max-width:760px) {
  .cont::after {        /*псевдоэлемент*/
  height: 50%;  
  background-size: cover;   
  }
  .cards-preg-grid {  
  grid-template-rows: repeat(21, 480px);
  }
  .cards-christ-grid {
    grid-template-rows: repeat(18, 480px);
  }
  .cards-wedding-grid {
    grid-template-rows: repeat(36, 480px);
  }
  .cards-women-grid {
    grid-template-rows: repeat(42, 480px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(52, 260px);
  }
  .cards-birth-grid {  
    grid-template-rows: repeat(20, 260px);
    }
  }
  @media all and (max-width:720px) {
  .myphoto {
  width: 100%;  
  height: auto;
  }
  .cards-family-grid {
    grid-template-rows: repeat(12, 220px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(16, 220px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(24, 220px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(24, 220px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(52, 220px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(20, 220px);
  }
  .cards-birth-grid { 
  grid-template-rows: repeat(20, 220px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(43, 240px);
  }

  }
  @media all and (max-width:700px) {
  .container_about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;     
  padding: 0px 20px;
  }
  .image_container {
  width: 70%;
  }
  .image_about {
  width: 100%;     
  margin: 10px;     
  }
  .allpars {   
  align-items: center;
  width: 100%;
  margin: 20px;
  }
  .cards-wedding-grid {
    grid-template-rows: repeat(36, 420px);
  }
  .cards-women-grid {
    grid-template-rows: repeat(42, 420px);
  }
  .cards-christ-grid {
    grid-template-rows: repeat(18, 420px);
  }
  .cards-preg-grid {  
    grid-template-rows: repeat(21, 460px);
    }
  }
  @media all and (max-width:650px) {
  nav {
  column-gap: 10px;
  }  
  .input_btn {
  letter-spacing: 2px;
  }
  .contact_par{
  width: 75%; 
  }
  }
  @media all and (max-width:600px) {
  nav {
  display: flex;
  flex-direction: column;
  align-items: center;    
  }
  .link {
  padding: 5px;
  font-size:calc(10px + 5 * (100 / 1280));
  margin: 10px;
  }
  .contact_head {   
  padding: 10px;
  line-height: 1.5;
  }
  .cards-family-grid {   
  grid-template-rows: repeat(15, 400px);
  grid-template-areas:
  "wide4"  
  "wide5"   
  "wide1"
  "wide3"
  "wide2"
  "wide2"   
  "wide6" 
  "wide7"
  "wide8"
  "wide9"
  "wide10"
  "wide10"
  "wide11"
  "wide13"
  "wide12"    
  ;
  }
  .cards-kids-grid { 
  grid-template-rows: repeat(23, 400px);
  grid-template-areas:
  "kids1"  
  "kids2"
  "kids3"
  "kids4" 
  "kids5"
  "kids6"
  "kids10"
  "kids7"
  "kids7"
  "kids9"
  "kids8" 
  "kids8"
  "kids11"
  "kids12"  
  "kids12"
  "kids13"
  "kids15"
  "kids14" 
  "kids16"         
  "kids17"
  "kids17"
  "kids18"
  "kids19"  
  ;
  }
  .cards-love-grid {
    grid-template-rows: repeat(55, 400px);
    grid-template-areas:
    "l1"    
    "l2"
    "l2"
    "l3"
    "l3"
    "l4" 
    "l4"
    "l5"
    "l5"
    "l6"    
    "l7"    
    "l8"
    "l9"    
    "l10" 
    "l11"    
    "l12"
    "l12"
    "l13"
    "l13"
    "l14"
    "l14"      
    "l15"
    "l15"
    "l16"   
    "l17"
    "l18"
    "l18"
    "l19"
    "l19"
    "l20"
    "l20"
    "l21"
    "l21"
    "l22"   
    "l23"
    "l23"
    "l24"
    "l24"
    "l25"
    "l25"
    "l26"
    "l26"
    "l27"    
    "l28"
    "l29"
    "l30"
    "l30"
    "l31"
    "l32"
    "l33"
    "l33"
    "l34"
    "l34"
    "l35"
    "l36"
  ;
  }
  .cards-newborn-grid {   
    grid-template-rows: repeat(33, 400px);
    grid-template-areas:
    "n1"
    "n1"
    "n2"
    "n3"
    "n4"
    "n4"
    "n5"
    "n6"
    "n10"
    "n7" 
    "n9"
    "n9"
    "n8" 
    "n11" 
    "n12"
    "n12"
    "n13"
    "n14"
    "n18"
    "n15"
    "n15"
    "n16" 
    "n17" 
    "n19" 
    "n20"
    "n20"
    "n21"
    "n21"
    "n24" 
    "n22" 
    "n22"
    "n23" 
    "n23" 
  ;
  }
  .cards-daughter-grid { 
    grid-template-rows: repeat(33, 400px);
    grid-template-areas:
    "d1" 
    "d2" 
    "d2"
    "d3"
    "d3" 
    "d4" 
    "d4"
    "d5"
    "d6"
    "d7"
    "d9"
    "d9" 
    "d10"
    "d10" 
    "d8" 
    "d11"
    "d11" 
    "d12" 
    "d12"
    "d13"
    "d14"
    "d14" 
    "d15"
    "d15"
    "d18"
    "d16"
    "d16" 
    "d17"
    "d17"
    "d19"
    "d19"
    "d20" 
    "d20"
  }
  .cards-ny-grid {   
    grid-template-rows: repeat(70, 400px);
    grid-template-areas:
    "ny1" 
    "ny2"
    "ny3"
    "ny4"
    "ny5" 
    "ny6" 
    "ny6"
    "ny7"
    "ny7"
    "ny8"
    "ny8"
    "ny9"
    "ny10" 
    "ny11" 
    "ny12"
    "ny12" 
    "ny13"
    "ny14" 
    "ny15" 
    "ny15"
    "ny16"
    "ny16" 
    "ny17"  
    "ny18" 
    "ny19"
    "ny19"
    "ny20"
    "ny21"
    "ny22"    
    "ny23"  
    "ny24" 
    "ny25"
    "ny26"
    "ny26"
    "ny27"
    "ny27"
    "ny28"
    "ny28"
    "ny29" 
    "ny30"
    "ny30" 
    "ny31"
    "ny31"
    "ny32" 
    "ny32"
    "ny33"
    "ny34" 
    "ny35"
    "ny36" 
    "ny37"
    "ny37" 
    "ny38"
    "ny38"
    "ny39" 
    "ny40"
    "ny41"
    "ny42"
    "ny43"  
    "ny44" 
    "ny45" 
    "ny45" 
    "ny46"
    "ny47"
    "ny48" 
    "ny48" 
    "ny49"
    "ny49" 
    "ny50"
    "ny51"
    "ny52"
    "ny53"
    ;
  }
  .cards-birth-grid {    
    grid-template-rows: repeat(31, 400px);
    grid-template-areas:
    "b1"
    "b2"
    "b3"
    "b4" 
    "b5"
    "b5"
    "b6"
    "b6"
    "b7"
    "b8" 
    "b9"
    "b9" 
    "b10"
    "b11"
    "b11" 
    "b12"
    "b12"
    "b13"
    "b13" 
    "b14"
    "b14"
    "b15"    
    "b16"
    "b17" 
    "b18"
    "b18"
    "b19"
    "b19" 
    "b20"
    "b20"
    "b21" 
    ;
  }
  .cards-winter-grid {  
    grid-template-rows: repeat(28, 400px);
    grid-template-areas:
    "wt1"
    "wt1" 
    "wt2"
    "wt3" 
    "wt4"    
    "wt5"
    "wt6"
    "wt6"
    "wt7"
    "wt7"
    "wt8"
    "wt8" 
    "wt9"
    "wt9" 
    "wt10"   
    "wt11"     
    "wt12"
    "wt13"
    "wt14"
    "wt15"
    "wt16"
    "wt16" 
    "wt17"
    "wt17"  
    "wt18"
    "wt18"
    "wt19"
    "wt19"
    ;
  }
  
  .cards-christ-grid {  
  grid-template-rows: repeat(30, 360px);
  grid-template-areas:
  "christ1" 
  "christ1" 
  "christ2"
  "christ2"
  "christ5"
  "christ3" 
  "christ3"
  "christ4"
  "christ6"
  "christ6"
  "christ7"
  "christ7"
  "christ8"
  "christ8"
  "christ9"
  "christ9"
  "christ10"
  "christ11"
  "christ11"             
  "christ13"
  "christ13"       
  "christ14"
  "christ14"
  "christ12"          
  "christ17"
  "christ19"
  "christ15"   
  "christ15"         
  "christ16"
  "christ18"          
  ;
  }
  .cards-preg-grid {   
  grid-template-rows: repeat(38, 400px);
  grid-template-areas:
  "preg1"
  "preg1"
  "preg2"
  "preg2"
  "preg3"
  "preg3"
  "preg4"
  "preg4"
  "preg5"
  "preg5"
  "preg6"
  "preg6"
  "preg7"
  "preg7"
  "preg8"
  "preg9"
  "preg10"
  "preg10"
  "preg11"
  "preg11"
  "preg12"
  "preg12"
  "preg13"
  "preg13"
  "preg14"
  "preg14"
  "preg15"
  "preg15"
  "preg16"
  "preg16"
  "preg17"            
  "preg17"
  "preg18"
  "preg19"
  "preg19"
  "preg20"
  "preg21"
  "preg22"
  "preg23"            
  ;
  }
  .cards-wedding-grid {    
    grid-template-rows: repeat(72, 360px);
    grid-template-areas:
    "w2"
    "w2"
    "w1"
    "w5"
    "w5"
    "w6"
    "w6"
    "w7"
    "w3"
    "w3"
    "w4"
    "w4"
    "w8"
    "w8" 
    "w9"
    "w9"
    "w10"
    "w10"
    "w12"
    "w12"
    "w11"
    "w11"
    "w13"
    "w14"
    "w14"
    "w15"
    "w15"
    "w17"
    "w16" 
    "w16"
    "w18"
    "w24"
    "w24"
    "w23"
    "w23"
    "w25"
    "w25"
    "w21"
    "w21"
    "w22"
    "w22"
    "w19"
    "w19"
    "w20"
    "w20"  
    "w31"
    "w31"
    "w32"
    "w32"
    "w26"
    "w27"
    "w27"
    "w28"
    "w29"
    "w29"
    "w30"
    "w30"
    "w33"
    "w35"
    "w38"
    "w38"
    "w39"
    "w39"
    "w36"
    "w36"
    "w37"
    "w37"
    "w34"
    "w40"
    "w40"
    "w41"
    "w41"
    ;
  }
  .cards-women-grid {  
    grid-template-rows: repeat(93, 360px);
    grid-template-areas:
    "wm1"
    "wm1"
    "wm2"
    "wm2"
    "wm3"
    "wm3" 
    "wm4"
    "wm4"
    "wm5"
    "wm5"
    "wm6"
    "wm6"
    "wm7"
    "wm7"
    "wm8"
    "wm9"
    "wm10"
    "wm10"
    "wm11"
    "wm11"      
    "wm16"
    "wm16" 
    "wm12"
    "wm12"
    "wm13"
    "wm13"
    "wm14"
    "wm14"
    "wm15"
    "wm15"
    "wm17"
    "wm17"
    "wm19"
    "wm19"
    "wm18"
    "wm18"
    "wm20"
    "wm20"
    "wm21"
    "wm21"
    "wm22"
    "wm22"
    "wm24"
    "wm24"
    "wm23"
    "wm23"
    "wm25"
    "wm25"
    "wm26"
    "wm26"
    "wm27"
    "wm27"
    "wm28"
    "wm28"
    "wm29"
    "wm30"
    "wm30"
    "wm31"
    "wm31"
    "wm32"
    "wm32"
    "wm33"
    "wm33"
    "wm34"
    "wm35" 
    "wm36"
    "wm36"
    "wm37"
    "wm38"
    "wm39"
    "wm39"
    "wm40"
    "wm40"
    "wm41"
    "wm42"
    "wm43"
    "wm43"
    "wm44"
    "wm44"
    "wm45"
    "wm45"
    "wm46"
    "wm46"
    "wm47"
    "wm47"
    "wm48"
    "wm49"
    "wm49"
    "wm50"
    "wm51"
    "wm51"
    "wm52"
    "wm52"
    ;
  }
  
  }
  @media all and (max-width:550px) {
    .cards-wedding-grid {    
      grid-template-rows: repeat(72, 320px);
    }
    .cards-women-grid {  
      grid-template-rows: repeat(93, 320px);
    }
    .cards-christ-grid {
      grid-template-rows: repeat(30, 320px);
    }
    .cards-preg-grid {  
      grid-template-rows: repeat(36, 360px);
      }
    .cards-family-grid {
      grid-template-rows: repeat(15, 360px);
    }
    .cards-kids-grid {
      grid-template-rows: repeat(23, 360px);
    }
    .cards-daughter-grid {
      grid-template-rows: repeat(33, 360px);
    }
    .cards-newborn-grid {
      grid-template-rows: repeat(33, 360px);
    }
    .cards-ny-grid {
      grid-template-rows: repeat(70, 360px);
    }
    .cards-winter-grid {
      grid-template-rows: repeat(28, 360px);
    }
    .cards-birth-grid {  
      grid-template-rows: repeat(31, 360px);
      }
  }
  @media all and (max-width:500px) {
  .right {
  width: 50%;   
  }
  .left {
  width: 50%; 
  }
  .input_par {  
  letter-spacing: 1px;  
  }
  .cards-family-grid {
    grid-template-rows: repeat(15, 340px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(23, 340px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(33, 320px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(33, 320px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(70, 320px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(28, 320px);
  }
  .cards-birth-grid {  
    grid-template-rows: repeat(31, 320px);
    }
    .cards-women-grid {  
      grid-template-rows: repeat(93, 280px);
    }
    .cards-wedding-grid {    
      grid-template-rows: repeat(72, 280px);
    }
    .cards-christ-grid {
      grid-template-rows: repeat(30, 280px);
    }
    .cards-preg-grid {  
      grid-template-rows: repeat(36, 340px);
    }

  }
  @media all and (max-width:450px) {  
  .header {
  letter-spacing: 0px;
  }    
  .contact_image {  
  padding: 5px;
  }
  .contact_par{
  width: 90%;    
  }
  .contact_head {   
  padding: 10px;
  line-height: 1.3;
  width: 100%;
  }
  .text_input:not([type=submit]) {
  margin: 5px;
  padding: 5px;
  width: 100%;
  }
 .cards-birth-grid {
  grid-template-rows: repeat(31, 290px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(23, 290px);
  }
  .cards-family-grid {
    grid-template-rows: repeat(15, 290px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(55, 290px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(33, 280px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(33, 280px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(70, 280px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(28, 280px);
  }
  .cards-women-grid {  
    grid-template-rows: repeat(93, 260px);
  }
  .cards-wedding-grid {    
    grid-template-rows: repeat(72, 260px);
  }
  .cards-christ-grid {
    grid-template-rows: repeat(30, 260px);
  }
  .cards-preg-grid {  
    grid-template-rows: repeat(36, 300px);
  }

  }
  @media all and (max-width:395px) {  
  .header{
  text-align: center;
  font-size:  calc(10px + 15 * (100vw / 1280));
  }
  .head_par {
  font-size:  calc(10px + 10 * (100vw / 1280));
  } 
  .text_input:not([type=submit]) {    
  padding: 2px;     
  }
 .cards-birth-grid { 
  grid-template-rows: repeat(31, 260px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(55, 260px);
  }
  .cards-family-grid {
    grid-template-rows: repeat(15, 260px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(23, 260px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(33, 240px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(33, 240px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(70, 240px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(28, 240px);
  }
  .cards-women-grid {  
    grid-template-rows: repeat(93, 240px);
  }
  .cards-wedding-grid {    
    grid-template-rows: repeat(72, 240px);
  }
  .cards-christ-grid {
    grid-template-rows: repeat(30, 240px);
  }
  .cards-preg-grid {  
    grid-template-rows: repeat(36, 260px);
  }
  
  }
  @media all and (max-width:370px) {     
  .linkPhoto {  
  letter-spacing: 3px;
  }
  .cards-women-grid {  
    grid-template-rows: repeat(93, 200px);
  }
  .cards-wedding-grid {    
    grid-template-rows: repeat(72, 200px);
  }
  .cards-christ-grid {
    grid-template-rows: repeat(30, 200px);
  }
  .cards-preg-grid {  
    grid-template-rows: repeat(36, 240px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(55, 240px);
  }
  .cards-family-grid {
    grid-template-rows: repeat(15, 240px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(23, 240px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(33, 220px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(33, 220px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(70, 220px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(28, 220px);
  }
  .cards-birth-grid {  
    grid-template-rows: repeat(31, 220px);
    }
  }
  @media all and (max-width:300px) {   
  .container_image {
  padding:10px;
  }
  .cards-birth-grid  {     
  grid-template-rows: repeat(31, 200px);
  }
  .cards-love-grid {
    grid-template-rows: repeat(55, 210px);
  }
  .cards-family-grid {
    grid-template-rows: repeat(15, 210px);
  }
  .cards-kids-grid {
    grid-template-rows: repeat(23, 200px);
  }
  .cards-daughter-grid {
    grid-template-rows: repeat(33, 200px);
  }
  .cards-newborn-grid {
    grid-template-rows: repeat(33, 200px);
  }
  .cards-ny-grid {
    grid-template-rows: repeat(70, 200px);
  }
  .cards-winter-grid {
    grid-template-rows: repeat(28, 200px);
  }
  .cards-women-grid {  
    grid-template-rows: repeat(93, 180px);
  }
  .cards-wedding-grid {    
    grid-template-rows: repeat(72, 180px);
  }
  .cards-christ-grid {
    grid-template-rows: repeat(30, 180px);
  }
  .cards-preg-grid {  
    grid-template-rows: repeat(36, 200px);
  }

  }